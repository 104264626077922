import React, { useState, useEffect, useContext } from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/common/Layout"
import Idealline from "../components/idealline/."
import { ClientOnly } from "../components/common"
import { MetaData } from '../components/common/meta'
import kartingImage from '../images/go-karting-driver.jpg'
import OnboardVideos from "../components/tracks/OnboardVideos"
import { Heart } from "@styled-icons/bootstrap/Heart"
import { CheckCircleFill as Check } from "@styled-icons/bootstrap/CheckCircleFill"
import Confetti from '../components/common/Confetti'
import ProfileThumb from '../components/idealline/ProfileThumb'
import FirebaseProvider from "../components/idealline/Firebase"
import { FirebaseContext } from "../components/idealline/Firebase"
import { PostFeed } from '../components/common'
import { BrowserRouter } from "react-router-dom"
import List from "../components/idealline/List"

const maxShownAvatarCount = 4

function fillUpWithFake(arr) {
    let cpy = []
    if(arr.length < maxShownAvatarCount) {
        const basePath = "https://i.pravatar.cc/150?img="
        for(let i = 1; i <= maxShownAvatarCount - arr.length; i++) {
            cpy.push({url: basePath + i})
        }        
    }
    return cpy
}

function SavedTrackAvatarsFilledUp({ trackId }) {
    const [realAvatars, setRealAvatars] = useState([])
    const auth = useContext(FirebaseContext)
    
    useEffect(() => {
        if (!trackId) return
        
        const firebase = auth.app
        const tracksDb = firebase.firestore().collection(`tracks`)

        return tracksDb.doc(trackId.toString()).onSnapshot((track) => {
            if (!track.exists) {
                setRealAvatars([])
                return
            }
            const data = track.data()
            const uids = data.favorites?.map((fav) => fav.uid)
            setRealAvatars(uids)

        })
    }, [trackId])

    const fakeAvatars = fillUpWithFake(realAvatars || [])
    const cappedRealAvatars = (realAvatars || []).slice(0, maxShownAvatarCount)

    return <>
        {
            fakeAvatars.map(item => <ProfilePic
                key={item.url}
                image={item.url}
            />)
        }
        {
            cappedRealAvatars.map(item => <ProfileThumb
                key={item}
                uid={item}
                showName={false}
                imageClassname="rounded-full w-9 h-9 border-2 border-white"
            />)
        }
           
    </>
}

const ProfilePic = ({ image, url, alt }) => {
    return (
        <img
            className="rounded-full w-9 h-9 border-2 border-white"
            src={image}
            alt={alt}
        />
    )
}

function SavedButton({ trackId }) {
    if(!trackId) throw 'trackId is required'

    const auth = useContext(FirebaseContext)
    const { user, userPublic } = auth
    const firebase = auth.app
    const tracksDb = firebase.firestore().collection(`tracks`)
    const usersDb = firebase.firestore().collection(`users`)
    const {requireAuth} = auth
    
    let [saved, setSaved] = useState(false)

    useEffect(() => {
        if (!user) return

        async function fetchSaved() {
            const track = await tracksDb.doc(trackId.toString()).get()
            if (!track.exists) return

            const trackData = track.data()
            
            const userEntry = trackData.favorites?.find((t) => t.uid === user.uid)
            if (userEntry) {
                setSaved(userEntry)
            }
        }
        fetchSaved()
        
    }, [trackId, user])

    const onSaved = async () => {
        const track = tracksDb.doc(trackId.toString())
        const userProfile = usersDb.doc(userPublic.username)

        if(!saved) {
                const data = {
                    created: firebase.firestore.Timestamp.now(),
                    trackId,
                    uid: user.uid,
                }
                await track.set(
                    {
                        favorites:
                            firebase.firestore.FieldValue.arrayUnion(data),
                    },
                    { merge: true }
                )

                await userProfile.set(
                    {
                        favorites:
                            firebase.firestore.FieldValue.arrayUnion(data),
                    },
                    { merge: true }
                )

                setSaved(data)
        } else {
                await track.set(
                    {
                        favorites: firebase.firestore.FieldValue.arrayRemove(saved),
                    },
                    { merge: true }
                )

                await userProfile.set(
                    {
                        favorites: firebase.firestore.FieldValue.arrayRemove(saved),
                    },
                    { merge: true }
                )
                
                setSaved(false)
        }
    }

    return (
        <button
            className="w-24 flex items-center justify-center px-3 py-1 text-base uppercase  rounded-full text-white focus:outline-none bg-indigo-800 focus:ring hover:bg-indigo-700 hover:text-white"
            onClick={() => requireAuth(onSaved)}
        >
            {saved ? (
                <>
                    <Check className="h-4 w-4 mr-1" />
                    Saved
                    <Confetti />
                </>
            ) : (
                <>
                    <Heart className="h-4 w-4 mr-1" />
                    Save
                </>
            )}
        </button>
    )
}


const Track = ({ params, data, location, pageContext }) => {
    const {
        ID: Id,
        Name: name,
        Gmaps_Place_Name: place_name,
        Gmaps_Rating: place_rating,
        Gmaps_Place_Id: place_id,
        Country: country,
        Url: filename,
        Location
    } = data.airtable.data
    const lat = +Location.split(',')[0]
    const lng = +Location.split(',')[1]

    const country_path = data.worldcitiesFilteredCsv?.path

    let formattedName = name
    if (place_name?.toLowerCase().includes(name.toLowerCase())) formattedName = place_name
    else if (place_name && place_name != "") formattedName = name + " - " + place_name

    let title = `${formattedName} Go Karting, Outdoor track layout and Onboard Videos`
    let description = `${formattedName} Go karting, track layout and onboard Videos.`

    const line = data.line
    if(line) {
        title = `${line.vehicle ? line.vehicle : ''} Racing Line for ${formattedName} - Ideal Acceleration and Braking Points`
        description = `Racing line for ${formattedName}. Find your ideal, turn-in, braking and acceleration points on your track.`
    }

    return (
        <>
            <MetaData
                title={title}
                description={description}
                location={location}
                image={kartingImage}
            />
            <Layout>
                <article className="post-full {{ page.subclass }} {% unless page.cover %}no-image{% endunless %}">
                    <header className="post-full-header">
                        <section className="post-full-meta">
                            <time className="post-full-meta-date">
                                <a href="/go-karting-tracks">Go-Kart</a>
                            </time>
                            {country && (
                                <>
                                    <span className="date-divider">/</span>
                                    <a href={country_path}>{country}</a>
                                </>
                            )}
                        </section>
                        <h1 className="post-full-title">{formattedName}</h1>
                        {/* <div className="flex flex-col items-center mt-6 mx-auto h-full w-max  rounded-xl relative overflow-hidden md:flex md:items-center p-5 shadow border border-gray-300">
                            <div className="font-bold leading-none mb-3">
                                Racers here
                            </div>
                            <div className="flex items-center">
                                <div className="flex items-center mr-3 ">
                                    <div className="flex items-center -space-x-3">
                                    <ClientOnly>
                                        <FirebaseProvider>
                                            <SavedTrackAvatarsFilledUp trackId={Id}></SavedTrackAvatarsFilledUp>
                                        </FirebaseProvider>
                                    </ClientOnly>
                                    </div>
                                </div> 
                                <ClientOnly>
                                        <FirebaseProvider>
                                            <SavedButton trackId={Id} />
                                        </FirebaseProvider>
                                </ClientOnly>
                            </div>
                        </div> */}
                    </header>
                    <div className="min-h-[400px]">
                    <ClientOnly>
                        <Idealline
                            name={name}
                            placeId={place_id}
                            lat={lat}
                            lng={lng}
                            apikey={process.env.GATSBY_GMAPS_API_KEY}
                            zoom={19}
                            url={location.pathname}
                        />
                    </ClientOnly>
                    </div>

                    {filename === "portimao" && (
                        <div>
                            <h1>Algarve Portimao Races</h1>
                            <p>
                                We hold regular races at the Portimao karting track. Learn more
                                and join us on our
                                <a
                                    href="https://open-racer.com/races/169190000000009003"
                                    className="ml-1 border-b-2 border-blue-100 text-blue-900"
                                >
                                    Algarve Portimao Karting Races.
                                </a>
                            </p>
                            <p>
                                Begin with a 15-minute qualifier, line up, then speed off into a 30-minute race. So, strap in and let's race!
                            </p>
                        </div>
                    )}
                    <h1>Onboard Videos</h1>
                    <p>
                        Find onboard videos for {formattedName} by using the
                        search bar to fine tune your Youtube results. For
                        example 'karting', 'rotax' or any other awesome racer 👇
                    </p>
                    <ClientOnly>
                        <OnboardVideos
                            query={`${formattedName} kart onboard`}
                        />
                        <BrowserRouter>
                            <FirebaseProvider>
                                <h1>Latest Racing Lines</h1>
                                <List
                                    wrapperClass="post-feed horizontal-feed lines"
                                    limit={10}
                                />
                            </FirebaseProvider>
                        </BrowserRouter>
                    </ClientOnly>

                    <h1>Getting Faster</h1>
                    <PostFeed
                        posts={data.allGhostPost.edges}
                        pageContext={pageContext}
                    />
                </article>
            </Layout>
        </>
    )
}

export default Track

// This is the page query that connects the data to the actual component. Here you can query for any and all fields
// you need access to within your code. Again, since Gatsby always queries for `id` in the collection, you can use that
// to connect to this GraphQL query.
export const query = graphql`
    query ($recordId: String, $country: String, $lineId: String) {
        allGhostPost(
            sort: { order: DESC, fields: [published_at] },
            limit: 3,
        ) {
          edges {
            node {
              ...GhostPostFields
            }
          }
        }
        airtable(recordId: { eq: $recordId }) {
            data {
                ID
                Name
                Url
                Gmaps_Place_Id
                Gmaps_Place_Name
                Gmaps_Rating
                Gmaps_Ratings_Total
                Location
                Subtype
                Type
                Country
              }
        }
        worldcitiesFilteredCsv( country: { eq: $country }) {
            path: gatsbyPath(
                filePath: "/countries/{worldcitiesFilteredCsv.country}"
            )
        }
        line(id: { in: [$lineId]}) {
            id
            vehicle
        }
    }
`
